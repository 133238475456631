import { defineStore } from "pinia";
import UserService from "@/services/UserService";
import { getError } from "@/includes/helpers";
import { usersMap } from "@/includes/users-map";
import { renameProperty } from "@/includes/rename.js";

export default defineStore("users", {
  state:() => ({
    user: {},
    newUser: {},
    editingUserId: null,
    users: [],
    roles: [],
    provider_dropdown:[],
    clients_dropdown:[],
    roles_dropdown: [],
    roles_dropdown_filter: [],
    categories:[],
    categories_dropdown: [],
    area_dropdown: [],
    paginate:[],
    table_options:0,
   user_filter:{
    search_name:null,
    search_email:null,
    search_role:null,
    search_category:null,
    search_group:null,
    search_provider:null,
    search_client:null
   },
   searchOptionsOpen_user:false,

    isLoading: false,
    error: null,
  }),
  actions: {
    resetNewUser() {
      this.newUser = {
        name: null,
        surname: null,
        role_id: null,
        email: null,
        telephone: null,
        password: null,
        category_id: null,
        area_id: null,
        password:null,
        provider_id: null,
        clients: null,
        seniority: null
      };
    },

    fetchUsers(url="/api/v1/users",query = null) {
      // if (this.table_options==1) {
      //   url="/api/v1/users/historical";
      // }
      this.isLoading = !this.isLoading;
      UserService.getUsers(url,this.user_filter)
        .then((response) => {
          this.users = usersMap(response.data.users.data);
          this.paginate = response.data.users;
        })
        .catch((error) => {
          this.isLoading = !this.isLoading;
          this.error = getError(error);
        });
    },
    async fetchUser(id) {
      await UserService.getUser(id)
        .then(response => {
          this.editingUserId = response.data.user.id;
          console.log(this.editingUserId);
          this.user = response.data.user;
          this.isLoading = !this.isLoading;
        })
        .catch((error) => {
          this.isLoading = !this.isLoading;
          this.error = getError(error);
        });
    },
    async fetchCategories() {
      this.isLoading = !this.isLoading;
      await UserService.getCategories()
        .then(response => {
          this.categories = response.data.categories;
          this.categories_dropdown = this.categories;
          this.isLoading = !this.isLoading;
        })
        .catch((error) => {
          this.isLoading = !this.isLoading;
          this.error = getError(error);
        });
    },
    async fetchProvider() {
      await UserService.get_provider()
        .then(response => {
          const data=response.data;
          this.provider_dropdown=data.map(item=>{
            return {id:item.id, name:item.provider_name};
          });
        })
        .catch((error) => {
         console.log(error);
        });
    },
    async fetchClients() {
      await UserService.get_clients()
        .then(response => {
          const data=response.data;
          this.clients_dropdown=data.map(item=>{
            return {id:item.id, name:item.company_name };
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async fetchRoles() {
      this.isLoading = !this.isLoading;
      await UserService.getRoles()
        .then(response => {
          this.roles_dropdown_filter=response.data.role_filter;
          this.roles = response.data.roles;
          this.roles_dropdown = this.roles
          this.isLoading = !this.isLoading;
        })
        .catch((error) => {
          this.isLoading = !this.isLoading;
          this.error = getError(error);
        });
    },
    async fetchArea() {
          await UserService.getTown()
        .then(response => {
          const area = response.data.area.map(item => {
            return {id: item.id, name: item.name}
          });
          area.push({id:0,name:"Todas"});
            this.area_dropdown = area
        })
        .catch((error) => {
          this.error = getError(error);
        });
    },
    
 

  }
});
